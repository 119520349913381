import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import VueMeta from 'vue-meta';

Vue.use(VueRouter);
Vue.use(VueMeta, {
  refreshOnceOnNavigation: true,
});

const routes: Array<RouteConfig> = [
  {
    path: '/:showSlug/:episodeSlug/',
    name: 'video-player',
    component: () => import(/* webpackChunkName: "index" */ '../views/index.vue'),
  },

  /* test route */
  {
    path: '/',
    name: 'test-video-player',
    component: () => import(/* webpackChunkName: "index" */ '../views/index.vue'),
  },

  /* Redirect Legacy URLs */
  {
    path: '/:showSlug/:episodeSlug/(simulcast|uncut)',
    redirect: '/:showSlug/:episodeSlug',
  },

  /* Remote Config for Firebase */
  {
    path: '/remote-config',
    name: 'remote-config',
    component: () => import(/* webpackChunkName: "index" */ '../views/RemoteConfig.vue'),
  },

  /* Log errors on 404s so DataDog sees them. */
  {
    path: '*',
    name: 'not-found-view',
    beforeEnter(to, from) {
      const details = JSON.stringify({
        'document.referrer': document.referrer,
        'route.to': to,
        'route.from': from,
      });
      console.error(`Video Not Found: ${details}`);
    },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to: Record<string, any>, _from, savedPosition: any) {
    if (savedPosition) {
      return savedPosition;
    }
    if (to.hash) {
      return {
        selector: to.hash,
      };
    }
    return { x: 0, y: 0 };
  },
});

export default router;
