/**
 * @fileoverview
 * Vuetify Plugin Configuration
 *
 * For treeshaking limitations see:
 * https://vuetifyjs.com/en/features/treeshaking/#limitations
 */

import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    dark: true,  //you don't actually need this line as it's for default
    themes: {
      dark: {
        primary: '#6200ee',
        secondary: '#e33693',
        accent: '#96fd04',
        error: '#fd0e02',
        success: '#009d12',
        info: '#00A5EF', // will change later
        warning: '#fd0e02',
        white:'#FFFFFF',
        black: '#000000',
        sunnyYellow: '#fef11c',
      },
    },
  },
});
