import Vue from 'vue';
import Vuex, { StoreOptions } from 'vuex';

type RootState = Record<string, any> & {
  error: Error | null;
  errors: Array<Error>;
};

Vue.use(Vuex);

const storeOptions: StoreOptions<RootState> = {
  state: {
    error: null,
    errors: [],
  },
  actions: {
    dismissError(context) {
      context.commit('DISMISS_ERROR');
    },
    error(context, payload: Error) {
      console.error(payload);
      context.commit('ERROR', payload);
    },
  },
  mutations: {
    DISMISS_ERROR(state) {
      state.error = null;
    },
    ERROR(state, payload) {
      state.error = payload;
      state.errors.push(payload);
    },
  },
};

export default new Vuex.Store(storeOptions);
