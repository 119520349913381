import Vue from 'vue';
import { sync } from 'vuex-router-sync';
import ShowDetails from '@funimation/comp-show-details';
import UserInfo from '@funimation/comp-user-info';
import VideoPlayer from '@funimation/comp-video-player';
import VideoPlayerAnalytics from '@funimation/comp-video-player-analytics';
import VideoPlayerCatalog from '@funimation/comp-video-player-catalog';
import VideoPlayerControls from '@funimation/comp-video-player-controls';
import VideoPlayerThumbOverlay from '@funimation/comp-video-player-thumbnails';
import VideoPlayerCore from '@funimation/comp-video-player-core';
import VideoPlayerEndScreen from '@funimation/comp-video-player-end-screen';
import VideoPlayerAdsGAM from '@funimation/comp-video-player-ads-gam';
import VideoPlayerMetaOverlay from '@funimation/comp-video-player-meta-overlay';
import VideoPlayerModal from '@funimation/comp-video-player-modal';
import VideoPlayerSkipCues from '@funimation/comp-video-player-skip-cues';
import WatchHistory from '@funimation/comp-watch-history';
import RemoteConfig from '@funimation/remote-config';

import { mapConfig, getFirebaseConfig } from '../src/config/helpers';
import App from './App.vue';
import rootConfig from './config/index.json';
import i18n from './i18n';
import vuetify from './plugins/vuetify';
import router from './router';
import store from './store';

Vue.config.productionTip = false;

const env = ({
  'www.funimation.com': 'prd',
  'www-v2.funimation.com': 'stg',
  'qa-www.funimationtest.com': 'qa',
  'uat-www.funimationtest.com': 'dev',
} as Record<string, string>)[window.location.host] || 'prd';

const firebaseConfig = getFirebaseConfig(env, rootConfig);

const config = mapConfig(env, rootConfig);

declare global {
  interface Window {
    app: any;
    initVueApp: () => Promise<void>;
    locale: string;
    region: string;
    'funi-build': any;
    dataLayer: Array<Record<string, any>>;
    datadogRumAddError: (error: Error, context?: any) => void;
  }
}

const initVueApp = async () => {
  sync(store, router);

  // init firebase
  const remoteConfig: any = await RemoteConfig(store, firebaseConfig);

  Vue.use(remoteConfig);

  // General Data Vue Plugins
  Vue.use(ShowDetails(
    store,
    {
      service: config.service_title,
      projectorService: config.projector_service,
      playerUrlFn: (showSlug: string, episodeSlug: string) => {
        return `/v/${showSlug}/${episodeSlug}`;
      },
    },
  ));
  Vue.use(UserInfo(store, {
    venueService: config.service_venue,
    geoService: config.service_geo,
  }));

  // Video Player Plugins
  const videoCuesService = config.video_cues_service;

  Vue.use(VideoPlayer(store)); // Must be first "Video Player" plugin
  Vue.use(VideoPlayerAnalytics(store));
  Vue.use(VideoPlayerCore(store));

  const catalogProjectorServiceBase = config.catalog_projector_service;
  const playbackServiceBase = config.playback_service;
  const playlistServiceBase = config.playlist_service;
  const titleServiceBase = config.service_title;
  Vue.use(VideoPlayerCatalog(store, {
    titleServiceBase,
    catalogProjectorServiceBase,
    playbackServiceBase,
    playlistServiceBase,
  }));
  Vue.use(VideoPlayerControls, { store });
  Vue.use(VideoPlayerAdsGAM(store, config.gam_settings));
  Vue.use(VideoPlayerEndScreen(store as any));
  Vue.use(VideoPlayerMetaOverlay(store as any));
  Vue.use(VideoPlayerModal(store as any));
  Vue.use(WatchHistory(store, {
    videoStreamTrackerBaseUrl: config.video_stream_tracker_service,
  }));
  Vue.use(VideoPlayerThumbOverlay, { store });
  Vue.use(
    VideoPlayerSkipCues,
    {
      store,
      videoCuesService,
    },
  );

  // Setup/Init Analytics
  const isDeveloper = window.localStorage.getItem('isDeveloper') === 'true' ? 1 : 0;
  const isQA = window.localStorage.getItem('isQA') === 'true' ? 1 : 0;
  store.state.config.analytics.setUserProperties({
    'is_developer': isDeveloper,
    'is_qa': isQA,
  });
  const trackUser = () => {
    store.state.config.analytics.logEvent('screen_view', {
      'page_location': store.state.route.fullPath,
      'page_path': store.state.route.path,
      'page_title': store.state.route.name,
    });
  };
  trackUser();
  store.watch((state: any) => state.userInfo, () => trackUser, { deep: true });
  store.watch((state: any) => state.route, () => trackUser, { deep: true });

  // i18n Setup
  // TODO: Move setLanguage to ./i18n.ts
  const setLanguage = (value: any) => {
    const esRegions = [ 'MX', 'CL', 'PE', 'CO' ];
    if (esRegions.includes(value)) {
      i18n.locale = 'es';
    } else if (value === 'BR') {
      i18n.locale = 'pt';
    }
    else {
      i18n.locale = 'en';
    }
    window.locale = i18n.locale;
    const html = window.document.querySelector('html');
    if (html) {
      html.setAttribute('lang', i18n.locale);
    }
  };
  setLanguage(window.region);
  // TODO: Move i18n.missing to ./i18n.ts
  i18n.missing = (locale, key) => {
    const error = new Error(`i18n key missing in ${locale} for ${key}`);
    window.datadogRumAddError(error);
  };

  const app = new Vue({
    router,
    store,
    vuetify,
    i18n,
    render: (h: any) => h(App),
  }).$mount('#app');

  // funi-build
  window['funi-build'] = {
    locale: i18n.locale,
    isDeveloper,
    isQA,
    app,
    env,
    config,
    stage: process.env.VUE_APP_STAGE,
    trigger: process.env.VUE_APP_TRIGGER,
    build: process.env.VUE_APP_BUILD,
  };

  // Expose mounted Vue App globally
  window.app = app;
};

const resolveGeoRace = () => {
  window.initVueApp = initVueApp;
  const mountPoint: any = document.querySelector('#app');
  if (mountPoint) {
    const isVueMounted = mountPoint.__vue__;
    if (!isVueMounted && window.region) {
      initVueApp();
    }
  }
};

resolveGeoRace();
